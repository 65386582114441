
@font-face {
    font-family: 'fat';
    src: local('fat'), url(./fonts/sayitfat.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'spectrum';
    src: local('spectrum'), url(./fonts/zx-spectrum.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'montserrat';
    src: local('montserrat'), url(./fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}





body{
    background-color: #023047;
    margin: 1.5em;
}


/* Welcome Page */


/* 


*/

  .col-main {
    flex: 1;  
  }  
  
  .col-complementary {
    flex: 1; 
  }
  
  
  @media only screen and (min-width: 640px) {
    .layout {
      display: flex;
      column-gap: 10%;
    }
  }
  
  .welcome-container {
    margin-top: 10em;
    max-width: 90em;
    margin-left: auto;
    margin-right: auto;
   }
  
  .col {
    padding: 1em;
    margin: 0 2px 2px 0;
    background: #023047;
  }

  .welcome-image{
  }

  .welcome-text{
    text-align: center;
  }

  .welcome-text p{
      font-size:larger;
      font-family: 'montserrat';
      color: white;
  }

  .welcome-text h1{
    font-size: xx-large;
    font-family: 'montserrat';
    color: white;
}

/* Welcome page end */


/* 



*/


/* Portfolio page */


/* 


*/
  .portfolio-col-main {
    flex: 1;  
  }  
  
  .portfolio-col-complementary {
    flex: 1;  
  }

  .portfolio-wrapper{
    flex: 1;
    height: 30em;
    overflow: auto;
    min-height: 0px;
    border: 5px solid white;
  }
  
  
  @media only screen and (min-width: 640px) {
    .layout {
      display: flex;
      column-gap: 10%;
    }
  }
  
  .portfolio-container {
    margin-top: 10em;
    margin-left: auto;
    margin-right: auto;

   }
  
  .portfolio-col {
    padding: 1em;
    margin: 0 2px 2px 0;
    background: #023047;
  }


  .portfolio-text{
    text-align: center;
  }

  .portfolio-text p{
      font-size:larger;
      font-family: 'montserrat';
      color: white;
  }

  .portfolio-text h1{
    font-size: xx-large;
    font-family: 'montserrat';
    color: white;
}

  .portfolio-element{
      height: 50%;
  }

  .portfolio-element-title{
      text-decoration: underline;
  }

  .portfolio-element-image{
      width: 100%;
  }



/* 




*/


/* Experience Page */


/* 


*/

.experience-col-main {
    flex: 1;  
  }  
  
  .experience-col-complementary {
    flex: 2;  
    background-color: white;
  }

  .experience-wrapper{
    flex: 1;
    height: 30em;
    overflow: auto;
    min-height: 0px;
    border: 5px solid white;
  }
  
  
  @media only screen and (min-width: 640px) {
    .layout {
      display: flex;
      column-gap: 10%;
    }
  }
  
  .experience-container {
    margin-top: 10em;
    margin-left: auto;
    margin-right: auto;

   }
  
  .experience-col {
    padding: 1em;
    margin: 0 2px 2px 0;
    background: #023047;
  }


  .experience-text{
    text-align: center;
  }

  .experience-text p{
      font-size:larger;
      font-family: 'montserrat';
      color: white;
  }

  .experience-text h1{
    font-size: xx-large;
    font-family: 'montserrat';
    color: white;
}

  .experience-element{
      width: 50%;
      display: inline-block;
  }

  .experience-element2{
    width: 50%;
    display: inline-block;


}

  .experience-element-title{
      text-decoration: underline;
  }

  .experience-element-image{
      width: 90%;
  }

  .experience-element-text{

    font-size:larger;
    font-family: 'montserrat';
    color: black;
    text-align: center;
}


/* Pixel Art page */
/* 




*/
.pixelart-text {
    position: absolute;
    left: 5%;
    top: 25%;
    display: inline-block;
    height: 70%;
    width: 30%;
    font-size: 0.5em;
    font-family: 'spectrum';
    overflow: scroll;
    color: white;
    text-align: center;
}


.pixelart-images{
    position: absolute;
    right: 5%;
    top: 20%;
    display: inline-block;
    border: 3px solid #FFB703;

    height: 65%;
    width:55%;
    font-size: 0.6em;
    overflow: scroll;
    color: white;
    text-align: center;
}


@media (max-width: 812px) {
    .pixelart-text {
        top: 25%;
        height: 80%;
        font-size: 0.3em;
    }
    .pixelart-images {
        top: 22%;
        height: 50%;
        width: 46%;
        right: 2%;
    }

}

@media (max-width: 376px) {
    .pixelart-text {
        top: 15%;
        height: 50%;
        width: 90%;
    }
    .pixelart-images {
        top: 120%;
        left: 1px;
        width: 99%;
        height: 200%;
    }
}


/* 




*/

/* Sketches Page */


.navbar-light .navbar-nav .nav-link {
    font-family: "montserrat";
    font-size:x-large;
    color: white;
  }

.color-nav{
    background-color: #023047;
}

#collapsed{
    float: right;
}

/* Modal */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {opacity: 0.7;}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 20%;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content, #caption {  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}